import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

$(function () {
  if ($('#advanced-search').length > 0) {
    $('.select').select2({
      width: '100%',
      dropdownParent: $('#advanced-search')
    });
  } else {
    $('.select').select2({
      width: '100%'
    });
  }

  if ($('.select-input-element').length > 0) {
    $('.select-input-element').select2({
      width: "100%",
      // select 移除搜尋功能
      minimumResultsForSearch: -1
    });
  }

  if ($('#manufacturingAreaSelect').length > 0) {
    // $('#manufacturingAreaSelect').val(39); //預設台灣
    // $('#manufacturingAreaSelect').trigger('change');
    $('#manufacturingAreaSelect').on("change", function (e) {
      if ($("#material-side-search-form").length > 0) {
        $("#material-side-search-form").submit();
      }
    });
  }

  if ($('#certificationDocSelect').length > 0) {
    $('#certificationDocSelect').on("change", function (e) {
      if ($("#material-side-search-form").length > 0) {
        $("#material-side-search-form").submit();
      } else if ($("#search-form").length > 0) {

      }
    });
  }
  const materialArr = [{
    input: $("#select-area"),
    outpput: $('#material_manufacturing_areas_other_zh'),
    otherText: "其他",
    mutiple: true,
  }, {
    input: $("#material_selling_type_id_ntd"),
    outpput: $('#selling_type_other_ntd'),
    otherText: "其他(others )",
    mutiple: false,
  }, {
    input: $("#material_selling_type_id_usd"),
    outpput: $('#selling_type_other_usd'),
    otherText: "其他(others )",
    mutiple: false,
  }]
  materialArr.forEach(function (item) {
    if (item.input.length > 0) {
      item.input.on('select2:select', function (e) {
        if (e.params.data.text === item.otherText) {
          item.outpput.removeClass('d-none')
        }
        if (!item.mutiple && e.params.data.text !== item.otherText) {
          item.outpput.addClass('d-none')
        }
      })
      if (item.mutiple) {
        item.input.on('select2:unselect', function (e) {
          if (e.params.data.text === item.otherText) {
            item.outpput.addClass('d-none')
          }
        })

      }

    }
  })

})

