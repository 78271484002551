$(function(){
    if($(".auto-h").length > 0){
        $('.auto-h').each(function () {
            this.setAttribute('style', 'height:' + (this.scrollHeight) + 'px;overflow-y:hidden;');
        }).on('input', function () {
            this.style.height = 'auto';
            this.style.height = (this.scrollHeight) + 'px';

            $(this).parents(".form-group").find(".text-maxcount").html($(this).val().length);
        });
    } // end length

    $(".toggle-content").on("click", function(e){
        let target_wrap = $(this).data('target-wrap');
        let target_number = $(this).data('target-number');
        let target_active = "true";

        if(target_number){
            target_active = target_number;
        }

        if($(this).val() == target_active){
            $(target_wrap).removeClass("d-none");
        }else{
            $(target_wrap).addClass("d-none");
        }
    })

    $(".single-toggle-content").on("change", function(e){
        let target_wrap = $(this).data('target-wrap');

        if (e.currentTarget.checked) {
            $(target_wrap).removeClass("d-none");
            $(target_wrap).find(".outline-input").attr("required",true);
        } else {
            $(target_wrap).addClass("d-none");
            $(target_wrap).find(".outline-input").attr("required",false);
        }
    });
    
})

// 表單驗證

//驗證email
function validateEmail(email){
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

//驗證密碼
function validatePW(password){
    var pattern = /^(?=.{6})(?=.*[a-z, A-Z])(?=.*[0-9])/
    var errorMsg = "密碼限制 6-12 個字元，設置的密碼必須包含英文及數字";
    return pattern.test(password);
}


//驗證數字
function isNumber(value){
    if(isNaN(value) || value < 0){
        return true
    }else{
        if(value % 1 != 0){
            return true
        }else{
            return false
        }
    }
}