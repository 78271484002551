$(function(){
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    svgimg();
    // $('.carousel').carousel({
    //     interval: 5000
    // });

    $("body").on("click", "#menuBtn", function(e){
        var target = $(this).data("target");

        $(target).toggleClass("show");
        $(this).toggleClass("open");
        $("#header").toggleClass("open");
        $("body").toggleClass("hidebar");

        $("#mainmenu-wrap").toggleClass("show");

        if($("#header").hasClass("index")){
            $(".brand-title").toggleClass("moveTop");
        }
        e.preventDefault();
    });

    $("body").on("click", ".scrollto", function(e){
        var target = $(this).attr("href");
        if($(target).length > 0){
            if(isSafari){
                $("body, html").animate({
                    scrollTop: $(target).offset().top - $("#header").innerHeight()
                }, 600);
            }else{
                window.scroll({
                    top: $(target).offset().top - $("#header").innerHeight(),
                    behavior: 'smooth'
                });
            }
        }
        e.preventDefault();
    });

    $("body").on("click", ".gotop", function(e){
        if(isSafari){
            $("body, html").animate({
                scrollTop: 0
            }, 600);
        }else{
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        }
        e.preventDefault();
    });


    $(".mainmenu").on("click", function(e){
        if($(window).width() <= 991){
            $(this).parents(".mainmenu-item").toggleClass("active");
            
            if($(this).hasClass("hassub")){
                e.preventDefault();
            }
        }
    })

    $(".advanced-btn").on("click", function(e){
        $(".advanced-wrap").removeClass("d-none");
        e.preventDefault();
    })

    $(".mobile-filter").on("click", function(e){
        $(this).find(".category-title").toggleClass("active");

        if(!$(".side-nav").hasClass("account-side")){
            $(".side-nav").css("top",$(this).find(".category-title").position().top + $(this).find(".category-title").height() + 10);
        }
        $(".toggle-nav").toggleClass("d-block");

        e.preventDefault();
    })

    $(".close-btn").on("click", function(e){
        $(".advanced-wrap").addClass("d-none");
        e.preventDefault();
    })

    $(".togglePw").on("click", function(event){
        var pwTarget = $(this).parents(".form-group").find("input");
  
        if(pwTarget.attr("type") == "password"){
            pwTarget.attr("type","text");
            $(this).find(".eye-close").addClass("d-none");
            $(this).find(".eye").removeClass("d-none");
        }else{
            pwTarget.attr("type","password");
            $(this).find(".eye-close").removeClass("d-none");
            $(this).find(".eye").addClass("d-none");
        }
        
        event.preventDefault();
    });

    $("a.disable, button.disable").on("click", function(e){
        e.preventDefault();
    })
})

$(window).on("scroll", function(){
    var scrolltop = $(window).scrollTop();
})

function svgimg(){
    $('img.svgimg').each(function(){
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
    
        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');
    
            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }
    
            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');
            
            // Check if the viewport is set, else we gonna set it if we can.
            if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }
    
            // Replace image with new SVG
            $img.replaceWith($svg);
    
        }, 'xml');
    });	
}

loadFont(
    'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Noto+Sans+TC:100,300,400,500,700'
)

function loadFont(url) {
    // the 'fetch' equivalent has caching issues
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
            let css = xhr.responseText
            css = css.replace(/}/g, 'font-display: swap; }')

            const head = document.getElementsByTagName('head')[0]
            const style = document.createElement('style')
            style.appendChild(document.createTextNode(css))
            head.appendChild(style)
        }
    }
    xhr.send()
}
