<template>
    <div :class="newCompareIDs.length > 0 ? 'compare-bottom active' : 'compare-bottom'">
        <div class="compare-scroll">
            <div class="compare-list">
                <div class="d-flex flex-wrap justify-content-center">
                    <div class="d-flex flex-wrap">
                        <div class="isotope-item" v-for="item in compareMaterials" :key="item.id">
                            <div class="img position-relative compare-item">
                                 <a :href="'materials/' + item.id">
                                    <template v-if="item.small_image_url">
                                        <img :src="item.small_image_url" :alt="item.name_zh" />
                                    </template>
                                    <template v-else>
                                        <img src="/images/material_default_pic.png" :alt="item.name_zh" />
                                    </template>
                                </a>
                                <div class="bottom">
                                    <span class="add add-compare" id="" @click="cancelCompare(item.id)"><span class="close-icon"></span>取消比對</span><span></span>
                                </div> <!-- /bottom -->
                            </div> <!-- /img -->
                            <h4 class="h4"><a :href="'materials/' + item.id">{{ item.name_zh }}</a></h4>
                        </div> <!-- /isotope-item -->
                    </div> <!-- /d-flex -->
                    <div class="compare-action">
                        <button type="submit" class="btn-default btn-outline compare-btn"  @click="startcompare()" :disabled="newCompareIDs.length > 1 ? false : true">材料比對</button>
                        <button type="button" class="compare-link" @click="toggleFilterModel()">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.875 1.125H1.875C1.18464 1.125 0.625 1.68464 0.625 2.375V7.375C0.625 8.06536 1.18464 8.625 1.875 8.625H6.875C7.56536 8.625 8.125 8.06536 8.125 7.375V2.375C8.125 1.68464 7.56536 1.125 6.875 1.125Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.875 12.375H1.875C1.18464 12.375 0.625 12.9346 0.625 13.625V18.625C0.625 19.3154 1.18464 19.875 1.875 19.875H6.875C7.56536 19.875 8.125 19.3154 8.125 18.625V13.625C8.125 12.9346 7.56536 12.375 6.875 12.375Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18.125 12.375H13.125C12.4346 12.375 11.875 12.9346 11.875 13.625V18.625C11.875 19.3154 12.4346 19.875 13.125 19.875H18.125C18.8154 19.875 19.375 19.3154 19.375 18.625V13.625C19.375 12.9346 18.8154 12.375 18.125 12.375Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.875 4.875L14.375 7.375L19.375 1.125" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            比對項目篩選
                        </button>
                        <button type="button" class="compare-link" @click="resetCompare()" :disabled="newCompareIDs.length > 0 ? false : true">
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.5 1.5L1.5 15.5" stroke="#4A5963" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
                                <path d="M15.5 15.5L1.5 1.5" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
                            </svg>
                            取消全部比對
                        </button>
                    </div> <!-- /compare-action -->
                </div> <!-- /d-flex flex-wrap -->
            </div> <!-- /compare-list -->
        </div> <!-- /compare-scroll -->
    </div> <!-- /compare-bottom -->

    <div :class="isOpenFilterModel ? 'compare-filter-model active' : 'compare-filter-model'">
      <div class="compare-model-body">
        <div class="resetCompare" @click="resetFilter()">取消全部選取</div>
        <div class="addCompare" @click="fillFilter()">新增全部選取</div>
        
        <div class="compare-filter">
          <h3 class="h3">比對項目篩選</h3>
          <div v-for="filterItem in computedFilterData" :key="filterItem.id" >
            <h3 class="filter-title text-start mb-4">{{ filterItem.title }}</h3>
            <div class="row mb-5">
              <div class="checkbox-item col-md-4"  v-for="innerFilterItem in  filterItem.list" :key='innerFilterItem.id'>
            
                  <input type="checkbox" class="" :value="innerFilterItem.id" name="compare_filter[]" :id="'compare_filter_' + innerFilterItem.id" @click="addFilter(innerFilterItem.meta_key)" checked="checked" />
                <label :for="'compare_filter_' + innerFilterItem.id">{{ innerFilterItem.title }}</label>
               
              </div> <!-- /col-md-4 -->
            </div> <!-- /row -->
            </div>

        </div> <!-- /compare-filter -->
        <div class="d-flex justify-content-center mt-5">
          <button class="btn-default btn-outline text-center" @click="toggleFilterModel('cancel')">取消</button>
          <button class="btn-default btn-outline text-center ms-5" @click="toggleFilterModel()">確定</button>
        </div>
      </div> <!-- /compare-model-body -->
      <div class="compare-filter-bg" @click="toggleFilterModel('cancel')"></div>
    </div> <!-- /compare-model -->
</template>

<script>
export default {
  data() {
    return {
        isOpenFilterModel: false,
        checkArr:[]
    };
  },
  props: {
    data: {
      type: String,
      default() {
        return ''
      }
    },
  },
  computed: {
    computedFilterData(){
        return JSON.parse(this.data)
    },

    compareMaterials: {
      get() {
        return this.$store.state.compareMaterials;
      },
      set(val) {
        this.$store.commit("setCompareMaterials", val);
      },
    },
    compareBtn: {
      get() {
        return this.$store.state.compareBtn;
      },
      set(val) {
        this.$store.commit("setCompareBtn", val);
      },
    },
    newCompareIDs: {
      get() {
        return this.$store.state.newCompareIDs;
      },
      set(val) {
        this.$store.commit("setNewCompareIDs", val);
      },
    },
    newFilterIDs: {
      get() {
        return this.$store.state.newFilterIDs;
      },
      set(val) {
        this.$store.commit("setNewFilterIDs", val);
      },
    },
    isOpenCompareModel: {
      get() {
        return this.$store.state.isOpenCompareModel;
      },
      set(val) {
        this.$store.commit("setIsOpenCompareModel", val);
      },
    },
  },
  created() {},
  methods: {
    getComparesMaterials(data = []) {
      this.compareMaterials = data;
      if (data.length === 0) {
        this.isOpenCompareModel = !this.isOpenCompareModel;
        // this.compareBtn = !this.compareBtn;
      }
    },
    closeModel() {
      this.isOpenCompareModel = !this.isOpenCompareModel;
    },
    toggleFilterModel(str='') {
      this.isOpenFilterModel = !this.isOpenFilterModel;
      if(this.isOpenFilterModel){
        const inputs = document.getElementsByName('compare_filter[]');
        for (var i = 0; i < inputs.length; i++) {
          if(inputs[i].checked){
            this.checkArr.push(i)
          }
        }
      }
      if(str === 'cancel'){
        const inputs = document.getElementsByName('compare_filter[]');
        for (var i = 0; i < inputs.length; i++) {
            if(this.checkArr.indexOf(i) !== -1){
              inputs[i].checked = true;
            }else{
              inputs[i].checked = false;
              
            }
        }
      }
    },
    startcompare() {
      let compareForm = document.getElementById("compare-form");
      compareForm.submit();
    },
    cancelCompare(id) {
      let self = this;
      this.newCompareIDs = this.newCompareIDs.filter((item) => item != id);
      $.ajax({
        url: "/api/v1/compare_id_to_session",
        type: "POST",
        data: {
          material_ids: this.newCompareIDs.join(),
        },
      }).done(function (result) {
        self.getComparesMaterials(JSON.parse(result.materials));
      });
    },
    resetCompare() {
      let self = this;
      this.newCompareIDs = [];
      this.newFilterIDs = [];
      $.ajax({
        url: "/api/v1/compare_id_to_session",
        type: "POST",
        data: {
          material_ids: null,
        },
      }).done(function (result) {
        self.getComparesMaterials();
      });
    },
    addFilter(filterID) {
      if (this.newFilterIDs.indexOf(filterID) == -1) {
        this.newFilterIDs.push(filterID)
      } else {
        this.newFilterIDs = this.newFilterIDs.filter(function (item) {
          return item !== filterID;
        });
      }
    },
    resetFilter(){
        const inputs = document.getElementsByName('compare_filter[]');
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].checked = false;
        }
    },
    fillFilter(){
        const inputs = document.getElementsByName('compare_filter[]');
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].checked = true;
        }
    }
  },
};
</script>
<style lang="scss" scoped>
  .compare-model-body {
    .addCompare,.resetCompare {
      position: absolute;
      right: 40px;
      cursor: pointer;
      text-decoration: underline;
      color: #007BD3;
    }
    .resetCompare {
      top: 70px;

    }
    .addCompare{
      top: 100px;
    }
}
</style>