<template>
  <template v-if="materiaList.length > 0">
    <div
      class="isotope-item"
      v-for="item in materiaList.slice(1 * page, 24 * (page + 1))"
      :key="item.id"
    >
      <div class="img position-relative compare-item">
        <div
          :class="
            newCompareIDs.indexOf(item.id) !== -1
              ? 'checkmarker active'
              : 'checkmarker'
          "
        >
          <span @click="addCompare(item.id)"></span>
        </div>
        <template v-if="item.small_image.thumb_2x.url">
          <a :href="'materials/' + item.id">
            <img :src="item.small_image.thumb_2x.url" :alt="item.name_zh" />
          </a>
        </template>
        <template v-else>
          <a :href="'materials/' + item.id">
            <img src="/images/material_default_pic.png" :alt="item.name_zh" />
          </a>
        </template>
        <div class="bottom">
          <span
            :class="
              newCompareIDs.indexOf(item.id) == -1 && compareBtn ? maxCompare ? 'add add-compare hidden' : 'add add-compare' : 'add add-compare hidden'
            "
            :id="'add-compare-' + item.id"
            @click="addCompare(item.id)"
            ><span class="plus-icon"></span>比對</span
          >
          <span></span>
          <template v-if="item.eco_rank">
            <span class="rank">Eco Rank：{{ item.eco_rank.name_zh }}</span>
          </template>
        </div>
        <!-- /bottom -->
      </div>
      <!-- /img -->
      <h4 class="h4">
        <a :href="'materials/' + item.id">{{ item.name_zh }}</a>
      </h4>
    </div>
    <!-- /isotope-item -->
  </template>
</template>

<script>
export default {
  data() {
    return {
      maxCompare: false
    };
  },
  props: ["materials", "compareids"],
  created() {
    if (this.materials) {
      this.materiaList = JSON.parse(this.materials);
    }
    this.newCompareIDs = JSON.parse(this.compareids);

    this.compareIdToSession();
    if(this.newCompareIDs.length >0){
      this.compareBtn = true
    }
  },
  computed: {
    materiaList: {
      get() {
        return this.$store.state.materiaList;
      },
      set(val) {
        this.$store.commit("setMateriaList", val);
      },
    },
    page: {
      get() {
        return this.$store.state.page;
      },
      set(val) {
        this.$store.commit("setPage", val);
      },
    },
    newCompareIDs: {
      get() {
        return this.$store.state.newCompareIDs;
      },
      set(val) {
        this.$store.commit("setNewCompareIDs", val);
      },
    },
    compareMaterials: {
      get() {
        return this.$store.state.compareMaterials;
      },
      set(val) {
        this.$store.commit("setCompareMaterials", val);
      },
    },
    compareBtn: {
      get() {
        return this.$store.state.compareBtn;
      },
      set(val) {
        this.$store.commit("setCompareBtn", val);
      },
    },
  },
  methods: {
    addCompare(id) {
      if (this.newCompareIDs.indexOf(id) == -1) {
        this.newCompareIDs.push(id)
      } else {
        this.newCompareIDs = this.newCompareIDs.filter(function (item) {
          return item !== id;
        });
      }

      this.compareIdToSession();

      if(this.newCompareIDs.length >= 8){
        this.maxCompare = true
      }else{
        this.maxCompare = false
      }

      this.$store.state.isOpenCompareModel = true
    },
    compareIdToSession() {
      let self = this;
      $.ajax({
        url: "/api/v1/compare_id_to_session",
        type: "POST",
        data: {
          material_ids: this.newCompareIDs.join(),
        },
      }).done(function (result) {
        self.getComparesMaterials(JSON.parse(result.materials));
      });
    },
    getComparesMaterials(data) {
      this.compareMaterials = data;
    },
  },
};
</script>