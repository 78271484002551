window.fill_data = function (target_id, value, type) {
    switch (type) {
        case 'int':
            $("#" + target_id).val(parseInt(value));
            break;
        case 'dec':
            $("#" + target_id).val(parseFloat(value));
            break;            
        case 'boolean':
            $("#" + target_id).attr('checked', value == "true");
            break;
        case 'select':
            $("#" + target_id).val(value);
            $("#select2-" + target_id + "-container").html($("#" + target_id + " option:selected").text())
            break;
        default:
            $("#" + target_id).val(value);
            break;
    }
}