import { createStore } from "vuex";
let mechanical_basic_parameters = {};

let mechanical_other_parameters = {};
const store = createStore({

    state: {
        materiaList: [],
        filters: { mechanical_basic_parameters, mechanical_other_parameters },
        isCompare: false,
        pageAmount: 0,
        page: 0,
        newCompareIDs: [],
        newFilterIDs: [],
        compareBtn: false,
        compareMaterials:[],
        isOpenCompareModel:false,
        searchQuery: []
    },
    mutations: {
        setSearchQuery(state, data) {
            state.searchQuery = data;
        },
        setMateriaList(state, data) {
            state.materiaList = data;
        },
        setFilters(state, data) {
            state.filters = data;
        },
        setIsCompare(state, data) {
            state.isCompare = data;
        },
        setPageAmount(state, data) {
            state.pageAmount = data;
        },
        setPage(state, data) {
            state.page = data;
        },
        setNewCompareIDs(state, data) {
            state.newCompareIDs = data;
        },
        setCompareBtn(state, data) {
            state.compareBtn = data;
        },
        setCompareMaterials(state, data) {
            state.compareMaterials = data;
        },
        setIsOpenCompareModel(state, data) {
            state.isOpenCompareModel = data;
        },
    },
})

export default store




