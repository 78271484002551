<template>
  <h4>{{ title }}</h4>
  <ul class="third-nav">
    <li class="feature" v-for="(item, index) in newModel" :key="index">
      <a href="#" class="third-link">
        {{ item.item_zh }}({{ item.unit_zh }})
      </a>
      <div>
        <input
          type="text"
          class="outline-input d-inline-block"
          placeholder="min"
          @keyup="materialCompare('max', item.id, group)"
        />
        <span>﹤數值﹤</span>
        <input
          type="text"
          class="outline-input d-inline-block"
          placeholder="max"
          @keyup="materialCompare('min', item.id, group, $event.target.value)"
        />
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      newModel: JSON.parse(this.model),
    };
  },
  props: ["model", "title", "group"],
  computed: {
    materiaList: {
      get() {
        return this.$store.state.materiaList;
      },
      set(val) {
        this.$store.commit("setMateriaList", val);
      },
    },
    filters: {
      get() {
        return this.$store.state.filters;
      },
      set(val) {
        this.$store.commit("setFilters", val);
      },
    },
    isCompare: {
      get() {
        return this.$store.state.isCompare;
      },
      set(val) {
        this.$store.commit("setIsCompare", val);
      },
    },
    newCompareIDs: {
      get() {
        return this.$store.state.newCompareIDs;
      },
      set(val) {
        this.$store.commit("setNewCompareIDs", val);
      },
    },
    page: {
      get() {
        return this.$store.state.page;
      },
      set(val) {
        this.$store.commit("setPage", val);
      },
    },
    compareBtn: {
      get() {
        return this.$store.state.compareBtn;
      },
      set(val) {
        this.$store.commit("setCompareBtn", val);
      },
    },
  },
  created() {
    this.getFilter();
  },
  methods: {
    materialCompare(size, id, group, val) {
      this.isCompare = true;
      let self = this;
      this.filters[group][id][size] = val;
      $.ajax({
        url: "/api/v1/filter",
        type: "POST",
        data: {
          filters: this.filters,
        },
      }).done(function (result) {
        self.setMateriaList(result);
      });
    },

    setMateriaList(data) {
      this.newCompareIDs = [];
      $.ajax({
        url: "/api/v1/compare_id_to_session",
        type: "POST",
        data: {
          material_ids: null,
        },
      }).done(function (result) {});
      this.page = 0;
      if (data.total > 0) {
        this.materiaList = data.materials;
      } else {
        this.materiaList = [];
      }
    },
    getFilter() {
      if (this.group === "mechanical_basic_parameters") {
        for (let index = 0; index < this.newModel.length ; index++) {
          this.filters.mechanical_basic_parameters[this.newModel[index].id] = {
            min: null,
            max: null,
          };
        }
      } else {
        for (let index = 0; index < this.newModel.length ; index++) {
          this.filters.mechanical_other_parameters[this.newModel[index].id] = {
            min: null,
            max: null,
          };
        }
      }
    },
  },
};
</script>