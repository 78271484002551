import { DirectUpload } from "@rails/activestorage"
import ImageResize from "@taoqf/quill-image-resize-module/image-resize.min";
import Quill from 'quill/quill';
export default Quill;

import htmlEditButton from "quill-html-edit-button";
import 'quill/dist/quill.snow.css'
import { template_HTML } from './template.js';

// embed video with ratio
import './embed-video.js'

Quill.register({
    "modules/imageResize": ImageResize,
    "modules/htmlEditButton": htmlEditButton
});

// var Size = Quill.import('attributors/style/size');
// Size.whitelist = ['14px', '16px', '18px'];
// Quill.register(Size, true);

document.addEventListener("DOMContentLoaded", function (event) {
    var toolbarOptions = [
        //[{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [3, 4, 5, 6, false] }],
        
        [{ 'color': [] }, { 'background': [] }], 
        [{ 'align': [] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['link', 'image', 'video'],
        
        ['blockquote'],
        ['code-block'],
      
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
      
        ['clean']                                         // remove formatting button
    ];

    // add font list
    // var Font = Quill.import('formats/font');
    // Font.whitelist = ['verdana', 'helvetica', 'Roboto'];
    // Quill.register(Font, true);

    var options = {
        modules: {
            clipboard: {
                matchVisual: false
            },
            toolbar: {
                container: toolbarOptions,  // Selector for toolbar container
                handlers: {
                  'image': function(){
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                    input.click();
                    input.onchange = async function() {
                        const item = this;
                        const file = input.files[0];
                        // Save current cursor state
                        const range = item.quill.getSelection(true);

                        var reader = new FileReader();
                        reader.onload = (function(theFile) {
                            return function(e) {
                                var img = e.target.result;
                                //console.log(e.target.result);       
                                
                                $.ajax({
                                    url: '/api/v1/upload_image',
                                    type: 'POST',
                                    data: { image: img }
                                }).done(function(result) {
                                    item.quill.insertEmbed(range.index, 'image', result.image_url); 
                                });;
                            };
                        })(file);
                        reader.readAsDataURL(file);
                    }.bind(this); // react thing
                  }
                }
            },
            htmlEditButton: {
            },
            imageResize: {
                displaySize: true,
                displayStyles: {
                  backgroundColor: 'black',
                  border: 'none',
                  color: 'white'
                },
                modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
            }
        },
        placeholder: '...',
        theme: 'snow'
    };

    var editor = new Array;
    if($(".content-editor").length > 0){
        $(".content-editor").each(function(editorID){
            $(this).attr("id","contentID" + editorID);
            // var editor = new Quill('.content-editor', options);
            editor[editorID] = new Quill("#contentID" + editorID, options);
            $(this).parents(".form-group").find("label").after("<span class='import-example' data-target='" + editorID + "'>載入範例</span>");
        })
    }

    $("body").on("click", ".import-example", function(){
        let targetIndex = $(this).data("target");
        if(editor[targetIndex].getLength() > 1){
            Swal.fire({
                icon: 'warning',
                text: '編輯器已有資料, 確定要貼上範例資料？',
                showCancelButton: true,
                confirmButtonText: '載入範例',
                cancelButtonText: '取消載入'
            }).then(function(result) {
                if (result.isConfirmed) {
                    editor[targetIndex].clipboard.dangerouslyPasteHTML(5, template_HTML);
                }
            });
        }else{
            editor[targetIndex].clipboard.dangerouslyPasteHTML(0, template_HTML);
        }
    })

    if($("form").length > 0){
        document.querySelector('form').onsubmit = function () {
            if($(".content-editor").length > 0){
                $(".content-editor").each(function(index){
                    $(this).parents(".validation-input").find('textarea[class=article-content]').val(editor[index].root.innerHTML);
                })
                // e.preventDefault();
            }
        };
    }

});
