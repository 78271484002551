import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css'

$(function(){
    $('.datepicker').flatpickr();
    $('.clockpicker').flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
    });
    $('.datetimepicker').flatpickr({
        enableTime: true,
        time_24hr: true
    });
})
