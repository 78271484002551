<template>
    <div class="search-loading" v-show="showLoading">
       <div class="loading">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    </div> <!-- /search-loading -->
    
    <div id="search_result">
        <div class="result-header w-100">
            <h2 v-if='false'>關於「{{ searchKeyword }}」搜尋結果</h2>
            <p>搜尋到 {{ searchCount }} 筆結果</p>
        </div> <!-- /result-header -->
        <div class="result-content" v-if="showType === 'material'">
            <div class="isotope-item compare-item" v-for="material in materials" :key="material.id">
                <div class="img">
                    <a :href="'materials/'+ material.id" class="d-block" target="_blank">
                        <img :src="material.small_image.thumb_2x.url ? material.small_image.thumb_2x.url : '/images/material_default_pic.png'" :alt="material.name_zh" class="img-fluid">
                    </a>
                </div>
                <h3 class="h4"><a :href="'materials/'+ material.id" class="text-decoration-none" target="_blank">{{ material.name_zh }}</a></h3>
            </div> <!-- /isotope-item -->
        </div> <!-- /result-content -->
        <div class="result-content" v-else-if="showType === 'product'">
            <div class="product-item" v-for="product in products" :key="product.id">
                <a :href="'products/'+ product.id" class="text-decoration-none" target="_blank">
                    <div class="product-info">
                        <div class="product-img">
                            <img :src="product.image.thumb_2x.url ? product.image.thumb_2x.url : '/images/material_default_pic.png'" :alt="product.name_zh" class="img-fluid">
                        </div>
                        <h3>{{ product.name_zh }}</h3>
                    </div>
                </a>
            </div>
        </div> <!-- /result-content -->
        <div class="result-content" v-else-if="showType === 'vendor'">
            <div class="card col-6 col-md-4 col-lg-3" v-for="vendor in vendors" :key="vendor.id">
                <div class="item-block h-100">
                    <div class="img w-100">
                        <a :href="'vendors/'+ vendor.id" target="_blank">
                            <img :src="vendor.image.list.url ? vendor.image.list.url : '/images/material_default_pic.png'" :alt="vendor.name_zh" class="border-radius-6 img-fluid">
                        </a>
                    </div>
                    <div class="info w-100 px-0">
                        <h3 class="h4"><a :href="'vendors/'+ vendor.id" target="_blank" class="text-decoration-none">{{ vendor.name_zh }}</a></h3>
                        <div class="company-info-list">
                            <dl class="d-flex flex-wrap">
                                <dt class="company-info-title" v-show="vendor.website"><img src="/images/website.svg" alt="website icon" class="img-fluid"></dt>
                                <dd class="company-info-txt" v-show="vendor.website"><a :href="vendor.website" target="_blank">官方網站</a></dd>

                                <dt class="company-info-title" v-show="vendor.address"><img src="/images/address.svg" alt="address icon" class="img-fluid"></dt>
                                <dd class="company-info-txt" v-show="vendor.address">{{ vendor.address }}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div> <!-- /card -->
        </div> <!-- /result-content -->
    </div> <!-- /search_result -->
</template>
<script>
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

export default {
  data() {
    return {
        searchKeyword: "",
        searchCount: 0,
        materials: [],
        products: [],
        vendors: [],
        showLoading: false,
        showResult: true,
        showType: "material"
    };
  },
  props: ["materials_path", "products_path", "vendors_path", "show_search_type", "get_data", "get_keyword", "get_count"],
  computed: {
    searchQuery: {
      get() {
        return this.$store.state.searchQuery;
      },
      set(val) {
        this.$store.commit("setSearchQuery", val);
      },
    },
    pageAmount: {
      get() {
        return this.$store.state.pageAmount;
      },
      set(val) {
        this.$store.commit("setPageAmount", val);
      },
    },
    page: {
      get() {
        return this.$store.state.page;
      },
      set(val) {
        this.$store.commit("setPage", val);
      },
    },
  },
  created() {},
  methods: {
    getMaterails(postData) {
        // 取得循環材料
        let self = this;
        $.ajax({
            url: self.materials_path,
            type: "post",
            data: postData,
            dataType: 'json'
        }).done(function (result) {
            self.materials = JSON.parse(result.materials);
            self.searchCount = result.result_count
            self.searchKeyword = result.search_key
            self.showLoading = !self.showLoading
            self.showResult = true
            self.pageAmount = Math.ceil(self.searchCount / 24);
        }).fail(function() {
            self.showLoading = !self.showLoading
        })
    },
    getProducts(postData) {
        // 取得循環產品
        let self = this;
        $.ajax({
            url: self.products_path,
            type: "post",
            data: postData,
            dataType: 'json'
        }).done(function (result) {
            self.products = JSON.parse(result.products);
            self.searchCount = result.result_count || 0
            self.searchKeyword = result.search_key
            self.showLoading = !self.showLoading
            self.showResult = true
            self.pageAmount = Math.ceil(self.searchCount / 24);
        }).fail(function() {
            self.showLoading = !self.showLoading
        });
    },
    getVendors(postData){
        // 取得循環廠商
        let self = this;
        let cycle_cate_arr = []
        self.$store.state.searchQuery.forEach((e)=>cycle_cate_arr.push( Number(e.id.slice(-1))))
        $.ajax({
            url: self.$store.state.searchQuery.length > 0 ? `${self.vendors_path}?cycle_cate=${cycle_cate_arr}` : self.vendors_path ,
            type: "post",
            data: postData,
            dataType: 'json'
        }).done(function (result) {
            self.vendors = JSON.parse(result.vendors);
            self.searchCount = result.result_count || 0
            self.searchKeyword = result.search_key
            self.showLoading = !self.showLoading
            self.showResult = true
            self.pageAmount = Math.ceil(self.searchCount / 24);
        }).fail(function() {
            self.showLoading = !self.showLoading
        }); 
    }
  },
  watch: {
    page: {
        handler(newvalue,oldvalue) {
            if (oldvalue) {
                $('#search-form').submit();
            }
        }
    }
  },
  mounted: function() {
    let self = this;
    self.showType = self.show_search_type;
    self.page = 1;

    $(".search-tags").removeClass("d-none");
    if(JSON.parse(self.get_data).length > 0){
        self.showResult = true;
        self.searchCount = self.get_count;
        self.searchKeyword = self.get_keyword;
        self.pageAmount = Math.ceil(self.searchCount / 24);

        if(self.showType == "material"){
            self.materials = JSON.parse(self.get_data);
        }else if(self.showType == "product"){
            self.products = JSON.parse(self.get_data);
        }else if(self.showType == "vendor"){
            self.vendors = JSON.parse(self.get_data);
        }
    }

    var input = document.getElementById("search-keyword-input");
    input.addEventListener("keypress", function(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            $('#search-form').submit();
        }
    });
    $('.search-icon').on('click', function(){
        $('#search-form').submit();
    })
    $('#certificationDoc2, #manufacturingAreaSelect2').select2({
        width: '100%'
    });

    $('#certificationDoc2, #manufacturingAreaSelect2').on("change", function (e) { 
        $('#search-form').submit();
    });

    $(".input_select").on("change", function(e){

        $('#search-form').submit();
    });

    $("input[type='radio'] ").on("change", function(e){
        let className = $(e.target).attr("class");
        let trueTarget = $(e.target).data("true-target");
        let falseTarget = $(e.target).data("false-target");

        if(className == "forCheck"){
            $("#" + trueTarget).prop("checked", true);
            $("#" + falseTarget).prop("checked", false);
        }

        $('#search-form').submit();
    });

    $("input[type='checkbox']").on("change", function(e){
        let inputID = $(e.target).attr("id");
        let inputLabel = $(e.target).parents(".search-item-group").find("h4").text();

        if(inputLabel != ""){
            inputLabel = inputLabel + ":";
        }

        let inputArray = {"id": inputID, "text": $("label[for='" + inputID + "']").text(), "parents": inputLabel};

        if($(e.target).is(':checked') == true){
            self.searchQuery.push(inputArray);
        }else{
            self.searchQuery = self.searchQuery.filter(function(item) {
                return JSON.stringify(item) !== JSON.stringify(inputArray)
            })
        }
        $('#search-form').submit();
    })

    $(".amount-input").on("change", function(e){
        let inputID = $(e.target).parents(".number-filter").attr("id");
        let inputLabel = $(e.target).parents(".number-filter").find("h4").text() + ":";
        console.log(inputID)
        let text = $("#" + inputID + "_min").val() + " - " + $("#" + inputID + "_max").val();
        let inputArray = {"id": inputID, "text": text, "parents": inputLabel};

        self.searchQuery = self.searchQuery.filter(function(item) {
            return item["id"] !== inputID
        })

        if($("#" + inputID + "_min").val() != "" || $("#" + inputID + "_max").val() != ""){
            self.searchQuery.push(inputArray);
        }

        $('#search-form').submit();
    })
    

    $('#search-form').on("submit", function( event ) {
        const country = $('#manufacturingAreaSelect2').val()
        const certificationDoc2 = $('#certificationDoc2').val()
        event.preventDefault();

            self.showLoading = !self.showLoading

            if($(".search-panel-1").hasClass('show')){
                self.showType = "material";
                self.getMaterails($(this).serialize() + "&page=" + self.page)
            }else if($(".search-panel-2").hasClass('show')){
                self.showType = "product";
                self.getProducts($(this).serialize() + "&page=" + self.page)
            }else if($(".search-panel-3").hasClass('show')){
                self.showType = "vendor";
                self.getVendors($(this).serialize() + "&page=" + self.page)
            }
            $('#pages').removeClass('d-none')

       
    });

    $(".tab-btn").on("click", function(){
        $(".tab-btn").removeClass("active");
        $(this).addClass("active");
        $('#pages').addClass('d-none')
        let target = $(this).data("target");
        $(".search-panel").removeClass("show");
        $(target).addClass("show");
        self.materials = [];
        self.products = [];
        self.vendors = [];
        self.showResult = true
        self.searchQuery.forEach(function(element){
            if($('#' + element.id).find("input").length == 0){
                document.getElementById(element.id).checked = false;
            }else{
                $('#' + element.id).find("input").val("");
            }
        });
        self.searchQuery = [];
        $('#search-form').submit();

    })

    let slideSpeed = 500;
    $(".filter-btn").on("click", function(){
        $(".filter-btn").not(this).removeClass("active");
        $(this).toggleClass("active");

        let target = $(this).data("target");
        $(".search-panel-1 .checked-item-group").not(target).slideUp(slideSpeed);
        $(target).slideToggle(slideSpeed);
    })

    $(document).on("click", function(e){
        // console.log($(e.target).parents(".select2").length);
        if($(".filter-btn").hasClass("active")){
            if($(e.target).parents(".search-panels").length == 0 && $(e.target).parents(".select2-container").length == 0){
                $(".filter-btn").removeClass("active");
                $(".search-panel-1 .checked-item-group").slideUp(slideSpeed);
            }
        }
    })
    $('.search-tags').on('click' ,'.search-tag-item',function () {
        const id =    $(this).data('id')
        document.getElementById(id).checked = false;
            
        if($('#' + id).find("input").length == 0){
            document.getElementById(id).checked = false;
        }else{
            $('#' + id).find("input").val("");
        }

        self.searchQuery = self.searchQuery.filter(function(item) {
            return item["id"] !== id
        })

        $('#search-form').submit();
    })
  }
};
</script>