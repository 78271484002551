<template>
  <div :class="isOpenCompareModel ? 'compare-model active' : 'compare-model'">
    <div class="compare-model-body">
      <div class="close" @click="closeModel()"></div>
      <div class="d-flex justify-content-end">
        <button
          type="submit"
          class="compare-btn outline-btn position-relative me-3"
          @click="startcompare()"
          :disabled="newCompareIDs.length > 1 ? false : true"
        >
          進行比對
        </button>
        <button
          class="reset-btn outline-btn"
          @click="resetCompare()"
          :disabled="newCompareIDs.length > 0 ? false : true"
        >
          取消所有比對
        </button>
      </div>

      <ul class="item-group">
        <li class="item" v-for="item in compareMaterials" :key="item.id">
          <template v-if="item.small_image_url">
            <img :src="item.small_image_url" :alt="item.name_zh" />
          </template>
          <template v-else>
            <img src="/images/material_default_pic.png" :alt="item.name_zh" />
          </template>
          <div class="content">
            <p>{{ item.name_zh }}</p>
            <p>
              類別 <span>{{ item.material_classification_name_zh }}</span>
            </p>
            <p>循環等級：{{ item.eco_rank_name_zh }}</p>
          </div>
          <div class="button" @click="cancelCompare(item.id)">取消比對</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: [],
  computed: {
    compareMaterials: {
      get() {
        return this.$store.state.compareMaterials;
      },
      set(val) {
        this.$store.commit("setCompareMaterials", val);
      },
    },
    compareBtn: {
      get() {
        return this.$store.state.compareBtn;
      },
      set(val) {
        this.$store.commit("setCompareBtn", val);
      },
    },
    newCompareIDs: {
      get() {
        return this.$store.state.newCompareIDs;
      },
      set(val) {
        this.$store.commit("setNewCompareIDs", val);
      },
    },
    isOpenCompareModel: {
      get() {
        return this.$store.state.isOpenCompareModel;
      },
      set(val) {
        this.$store.commit("setIsOpenCompareModel", val);
      },
    },
  },
  created() {},
  methods: {
    getComparesMaterials(data = []) {
      this.compareMaterials = data;
      if (data.length === 0) {
        this.isOpenCompareModel = !this.isOpenCompareModel;
        this.compareBtn = !this.compareBtn;
      }
    },
    closeModel() {
      this.isOpenCompareModel = !this.isOpenCompareModel;
    },
    startcompare() {
      let compareForm = document.getElementById("compare-form");
      compareForm.submit();
    },
    cancelCompare(id) {
      let self = this;
      this.newCompareIDs = this.newCompareIDs.filter((item) => item != id);
      $.ajax({
        url: "/api/v1/compare_id_to_session",
        type: "POST",
        data: {
          material_ids: this.newCompareIDs.join(),
        },
      }).done(function (result) {
        self.getComparesMaterials(JSON.parse(result.materials));
      });
    },
    resetCompare() {
      let self = this;
      this.newCompareIDs = [];
      $.ajax({
        url: "/api/v1/compare_id_to_session",
        type: "POST",
        data: {
          material_ids: null,
        },
      }).done(function (result) {
        self.getComparesMaterials();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.compare-model {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 30;
  background: rgba($color: #000000, $alpha: 0.5);
  &.active {
    display: block;
  }
  .compare-model-body {
    overflow: auto;
    padding: 20px 90px 20px 20px;
    position: absolute;
    width: 840px;
    height: 600px;
    background: white;
    border-radius: 15px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% - 95px));
    @media screen and (max-width: 992px) {
      width: calc(100% - 30px);
    }
    .item-group {
      .item {
        display: grid;
        gap: 20px;
        align-items: center;
        margin-bottom: 20px;
        padding: 20px;
        grid-template-columns: 125px 1fr 104px;
        @media screen and (max-width: 720px) {
          grid-template-columns: 1fr;
        }
        img {
          width: 125px;
          border-radius: 15px;
        }
        .button {
          position: relative;
          width: 104px;
          height: 32px;
          border-radius: 47px;
          border: 1px solid #000000;
          text-align: center;
          line-height: 32px;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 14px;
          background: #ffffff;
          cursor: pointer;
          &::before,
          &::after {
            position: absolute;
            left: 10px;
            content: "";
            width: 11px;
            height: 1px;
            top: 50%;
            transform: translateY(-50%);
            background: #000000;
          }
        }
      }
    }
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      &::before,
      &::after {
        position: absolute;
        top: 0px;
        left: 50%;
        content: "";
        width: 1px;
        height: 100%;
        background: #000000;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
</style>