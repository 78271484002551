import anime from 'animejs/lib/anime.es.js';

window.addEventListener(
    'DOMContentLoaded',
    function () {
        const wrapperEl = document.querySelector('.circle-animate');
        const numberOfEls = 90;
        const duration = 6000;
        const delay = duration / numberOfEls;

        if (document.body.contains(wrapperEl)){
            let tl = anime.timeline({
                duration: delay,
                complete: function() { tl.restart(); }
            });

            function createEl(i) {
                let el = document.createElement('div');
                const rotate = (360 / numberOfEls) * i;
                const translateY = -50;
                const hue = Math.round(360 / numberOfEls * i);
                el.classList.add('el');
                el.style.backgroundColor = '#000000';
                el.style.transform = 'rotate(' + rotate + 'deg) translateY(' + translateY + '%)';
                tl.add({
                    begin: function() {
                    anime({
                        targets: el,
                        backgroundColor: ['#000000'],
                        rotate: [rotate + 'deg', rotate + 10 +'deg'],
                        translateY: [translateY + '%', translateY + 10 + '%'],
                        scale: [1, 1.25],
                        easing: 'easeInOutSine',
                        direction: 'alternate',
                        duration: duration * .1
                    });
                    }
                });
                wrapperEl.appendChild(el);
            };
    
            for (let i = 0; i < numberOfEls; i++) createEl(i);

            // grid
            if (document.body.contains(document.querySelector('.staggering-axis-grid'))){
                let grid = anime({
                    targets: '.staggering-axis-grid .el',
                    translateX: anime.stagger(10, {grid: [14, 5], from: 'center', axis: 'x'}),
                    translateY: anime.stagger(10, {grid: [14, 5], from: 'center', axis: 'y'}),
                    rotateZ: anime.stagger([0, 90], {grid: [14, 5], from: 'center', axis: 'x'}),
                    delay: anime.stagger(200, {grid: [14, 5], from: 'center'}),
                    easing: 'easeInOutQuad',
                    complete: function() { setTimeout(function(){ grid.restart(); }, 1000) }
                });
            }
        }
    },
    true
)
