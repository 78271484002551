import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

document.addEventListener('DOMContentLoaded', function() {
  if($(".owl-carousel").length > 0){
    $(".owl-carousel").slick({
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      swip: true,
      variableWidth: false,
      autoplay: false,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
  } // end if

  if($(".thumb-carousel").length > 0  ){
    $('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: $('.thumb-carousel').hasClass('zoompic') ? true : false,
      fade: true,
      asNavFor: '.thumb-carousel',
      prevArrow: $('.thumbnail .prev'),
      nextArrow: $('.thumbnail .next'),
    });

    $('.thumb-carousel').slick({
      slidesToShow: $('.thumb-carousel').hasClass('zoompic') ? 3 : 5,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      dots: false,
      focusOnSelect: true,
      arrows: $('.thumb-carousel').hasClass('zoompic') ? false : true,
      infinite: true,
      prevArrow: '<button type="button" class="slick-prev"><span class="arrow left">Previous</span></button>',
      nextArrow: '<button type="button" class="slick-next"><span class="arrow right">next</span></button>',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        }
      ]
    });
  }

  if($(".rel-slide").length > 0){
    $(".rel-slide").slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      swip: true,
      variableWidth: false,
      autoplay: false,
      autoplaySpeed: 5000,
      infinite: true,
      prevArrow: '<button type="button" class="slick-prev"><span class="arrow left">Previous</span></button>',
      nextArrow: '<button type="button" class="slick-next"><span class="arrow right">next</span></button>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
  }
});
