import { Loader } from "@googlemaps/js-api-loader"
import { MarkerClusterer } from "@googlemaps/markerclusterer"

document.addEventListener('DOMContentLoaded', function () {
  const loader = new Loader({
    apiKey: "AIzaSyDUcpVXL4SWDvpa7FGs0ECRCflbmQVUl1k",
    version: "weekly"
  });

  let map
  let mapZoom
  const locations = []

  loader.load().then(() => {
    if ($("#map").length > 0) {
      if ($(window).height() <= 1024) {
        mapZoom = 7;
      } else {
        mapZoom = 8;
      }

      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: $("#map").data("lat"), lng: $("#map").data("lng") },
        zoom: mapZoom,
        mapTypeControl: false,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "administrative.neighborhood",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#c9c9c9"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          }
        ]
      });

      const infoWindow = new google.maps.InfoWindow({
        content: '',
        disableAutoPan: true,
      });

      const normalIcon = "/images/ping.svg";
      const activeIcon = "/images/ping-active.svg";
      $(".infoList").find(".infoWindow").each(function (index) {
        locations[index] = { lat: $(this).data("lat"), lng: $(this).data("lng"), title: $(this).data("title") }
      })

      const markers = locations.map((position, i) => {
        const marker = new google.maps.Marker({
          position,
          icon: normalIcon,
          label: {
            text: position["title"],
            className: "marker-label"
          }
        });

        marker.addListener("click", () => {
          // infoWindow.setContent(label);
          //infoWindow.open(map, marker);
          $(".infoWindow").removeClass("show");
          $(".infoWindow:eq(" + i + ")").addClass("show");

          for (var j = 0; j < markers.length; j++) {
            markers[j].setIcon(normalIcon);
          }
          marker.setIcon(activeIcon)
          map.setCenter(locations[i]);
        });
        return marker;
      });
      const svg = window.btoa(`
      <svg fill="blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <circle cx="12" cy="12" opacity=".6" r="7" />
        <circle cx="12" cy="12" opacity=".3" r="9" />
        <circle cx="12" cy="12" opacity=".2" r="11" />
      </svg>`);
      // create svg url with fill color
      const renderer = {
        render({ count, position }) {
          return new google.maps.Marker({
            label: { text: String(count), color: "white", fontSize: "12px" },
            position,
            icon: {
              url:`data:image/svg+xml;base64,${svg}`,
              scaledSize: new google.maps.Size(45, 45), 
          },
            // adjust zIndex to be above other markers
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          })
        }
      }
      // Add a marker clusterer to manage the markers.
      new MarkerClusterer({ markers, map, renderer: renderer });
} // end if length
  });
}); // DOMContentLoaded

// 測試座標
// const locations = [
//   { lat: 25.04798876082064, lng: 121.51742698505086 },
//   { lat: 25.059263240935216, lng: 121.54283286741862 },
//   { lat: 25.035468985332777, lng: 121.52678252957143 },
//   { lat: 25.041806795396614, lng: 121.55051471360754 },
//   { lat: 25.070614428243196, lng: 121.52060272049809},
//   { lat: 25.02539780351171, lng: 121.52944328079658},
// ];


