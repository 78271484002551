import { createApp } from 'vue/dist/vue.esm-bundler'
import Materials from '../../components/Materials'
import CompareInput from '../../components/CompareInput'
import CompareModel from '../../components/CompareModel'
import CompareBottom from '../../components/CompareBottom'
import SearchResult from '../../components/SearchResult'
import store from '../stores/materials'
document.addEventListener('DOMContentLoaded', () => {
    const app = new createApp({
        data() {
            return {
                isToggleNavOpen: [false, false, false, false,false, false, false],
                toggleNavFirst: ['MaterialRecycledFromType', 'eco_rank_id', 'material_selling_type_id', 'MaterialUsedOf', 'MaterialPotentialFor'],
                toggleNavSecond: ['material_classification_id', 'material_type_id', 'OpticalProperty', 'appearance_id', 'fire_id', 'electric_insulation_id', 'MaterialManufacturingArea', 'ManufacturingMethod', 'material_toxicity_id'],
                toggleNavThird: ['ColoringMethod', 'ColorsAlreadyDeveloped', 'yarn_generality_id', 'blended_type_id', 'technic_id', 'filament_generality_id', 'section_geometry_id', 'section_filling_id', 'section_detail_id', 'pattern_selection_id'],
                nowPage: 0
            }
        },
        components: {
            Materials, CompareInput, CompareModel, CompareBottom, SearchResult
        },
        created() {
            this.judgeThisToggle()
        },
        computed: {
            materiaList: {
                get() {
                    return this.$store.state.materiaList;
                },
                set() {
                    this.$store.commit('setMateriaList');
                },
            },
            searchQuery: {
                get() {
                    return this.$store.state.searchQuery;
                },
                set(val) {
                    this.$store.commit("setSearchQuery", val);
                },
            },
            isCompare: {
                get() {
                    return this.$store.state.isCompare;
                },
                set(val) {
                    this.$store.commit("setIsCompare", val);
                },
            },
            newCompareIDs: {
                get() {
                    return this.$store.state.newCompareIDs;
                },
                set(val) {
                    this.$store.commit("setNewCompareIDs", val);
                },
            },
            newFilterIDs: {
                get() {
                    return this.$store.state.newFilterIDs;
                },
                set(val) {
                    this.$store.commit("setNewFilterIDs", val);
                },
            },
            pageAmount: {
                get() {
                    return this.$store.state.pageAmount;
                },
                set(val) {
                    this.$store.commit("setPageAmount", val);
                },
            },
            page: {
                get() {
                    return this.$store.state.page;
                },
                set(val) {
                    this.$store.commit("setPage", val);
                },
            },
            compareBtn: {
                get() {
                    return this.$store.state.compareBtn;
                },
                set(val) {
                    this.$store.commit("setCompareBtn", val);
                },
            },
            isOpenCompareModel: {
                get() {
                    return this.$store.state.isOpenCompareModel;
                },
                set(val) {
                    this.$store.commit("setIsOpenCompareModel", val);
                },
            },
        },
        _methods: {
            removeTag(id) {
                document.getElementById(id).checked = false;
                
                if($('#' + id).find("input").length == 0){
                    document.getElementById(id).checked = false;
                }else{
                    $('#' + id).find("input").val("");
                }

                this.searchQuery = this.searchQuery.filter(function(item) {
                    return item["id"] !== id
                })

                $('#search-form').submit();
            },
            toggleNavOpen(index) {
                if (!this.isToggleNavOpen[index]) {
                    this.isToggleNavOpen = [false, false, false, false, false, false, false]
                }
                this.isToggleNavOpen[index] = !this.isToggleNavOpen[index]
            },
            startCompare(str) {
                if(!this.compareBtn){
                    let addCompare = document.querySelectorAll('.add-compare')
                    for (let index = 0; index < addCompare.length; index++) {
                        if (str === 'start') {
                            addCompare[index].classList.remove('hidden')
                        } else {
                            addCompare[index].classList.add('hidden')
                        }
                    }
                }
                this.compareBtn = !this.compareBtn      
            },
            pageChange(index) {
                if (this.pageAmount >= index &&  index > 0) {
                    this.page = index
                }
            },
            onPageChange(index){
                this.page = index
            },
            searchFocus(str, val) {
                if (str == 'focus') {
                    $('#searchForm').addClass('flex-row-reverse')
                } else {
                    if (val.length < 1) {
                        $('#searchForm').removeClass('flex-row-reverse')
                    }
                }
            },
            openModel() {
                this.isOpenCompareModel = !this.isOpenCompareModel
            },
            judgeThisToggle() {
                let url = window.location.href
                url = decodeURI(url)
                let arr = url.split('[');
                if(arr.length < 2) return
                let str = arr[1].slice(0, -1)
                if (this.toggleNavFirst.indexOf(str) != -1) {
                    this.isToggleNavOpen[0] = true
                    return
                }
                if (this.toggleNavSecond.indexOf(str) != -1) {
                    this.isToggleNavOpen[1] = true
                    return
                }
                if (this.toggleNavThird.indexOf(str) != -1) {
                    this.isToggleNavOpen[2] = true
                    return
                }
            },
            addCollect(ifLogin,productId,path,event){
                let btn = event.currentTarget
                // console.info('event.target', event.target);
                // console.info('event.currentTarget', event.currentTarget);

                if(ifLogin == true){
                    let header = $('#header')
                    $.ajax({
                      url: "/api/v1/product_collect",
                      type: "POST",
                      dataType: "JSON",
                      data: {
                        id: productId
                      },
                      success: function(res) {
                        if(res.state == "Collected"){
                            btn.classList.add('active')
                            header.append("<div class='behavior'> 已加入至 <a href='" + path + "'>產品收藏</a></div>")
                        }else{
                            btn.classList.remove('active')
                        }
                        setTimeout(() => {
                          $(".behavior").animate({
                            opacity: 0
                          }, 2000, function(){
                            $(this).remove();
                          })
                        }, 100);
                      } // end success
                    });
                }else{
                    // console.log('請先登入')
                    var matchModal = new bootstrap.Modal(document.getElementById('matchModal'), '');
                    matchModal.show();
                }
            }
        },
        get methods() {
            return this._methods
        },
        set methods(value) {
            this._methods = value
        },
        watch: {
            materiaList: {
                handler(val) {
                    if (val.length > 0) {
                        this.pageAmount = parseInt((val.length / 24) + 1)
                    }
                },
            }
        },
    }).use(store).mount('#app')
})